<template>
  <v-icon v-bind="$attrs" v-on="$listeners">
    {{ value ? '$star_on' : '$star_off' }}
  </v-icon>
</template>

<script>
export default {
  name: 'FavouriteIcon',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
